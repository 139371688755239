::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 10px;
}

h1,
h2,
h3,
h4 {
  font-family: Lato;
  font-style: normal;
}

h1 {
  font-size: calc(1.9rem + 1.5vw);
  font-weight: 400;
}

.bp {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 0;
}

h2 {
  font-weight: 400;
}

h3 {
  font-size: calc(1rem + 0.6vw);
  font-weight: 600;
}

h4 {
  font-size: calc(0.93rem + 0.3vw);
  font-weight: 400;
}

h5 {
  font-size: 0.89rem;
  font-weight: 700;
}

h6 {
  font-size: 0.82rem;
}

a {
  text-decoration: none;
  color: #00aeae;
}

.btn-default {
  padding: 1em;
  color: #505050;
  background: #00ffc2;
  border-radius: 3rem;
  border: none;
  width: 100%;
  max-width: 320px;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
}

.btn-default:hover {
  padding: 1em;
  color: #505050;
  background: #00ebb2;
  border-radius: 3rem;
  border: none;
  width: 100%;
  max-width: 320px;
  cursor: pointer;
}

.btn-default:focus {
  padding: 1em;
  color: #505050;
  background: #00ebb2;
  border-radius: 3rem;
  border: none;
  width: 100%;
  max-width: 320px;
  cursor: pointer;
}

.btn-default:disabled {
  padding: 1em;
  color: #505050;
  background: #e1fbf5;
  border-radius: 3rem;
  border: none;
  width: 100%;
  max-width: 320px;
  pointer-events: none;
}

.interest {
  background: #1181c81a;
  font-size: 10px;
  width: fit-content;
  padding: 4px 7.5px;
  text-transform: capitalize;
}

.form-check-input[type='radio'] {
  box-shadow: none;
  width: 20px;
  height: 20px;
  border: 0.5px solid #b6c0d2;
}

.form-check-input:checked[type='radio'] {
  background-color: #fff;
  background-image: url(./assets/images/radiocircle.svg);
  background-size: 75%;
  border: 0.5px solid #00aeae;
}

@media only screen and (min-width: 992px) {
  .interest {
    font-size: 14px;
    padding: 8px 17px 9px 14px;
  }
}
