.root{
    background-color: white;
    width:471px;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 41px 28px 52px 31px;
    margin: auto;
    margin-top: 191px;
}
.content{
  margin-bottom: 19px;
}
.close-button{
  background: #fff;
  border: 0;
}

