

   
    .log{
        margin-left: 73px;
        margin-bottom: -58px;
    }
    .terms2{
        border:none;
        position: absolute;
        border: none;
        background: transparent;
        color: #fff;
      
        margin-top: 7px;
        font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    }
    .privacy1{
      border:none;
        position: absolute;
        border: none;
        background: transparent;
        color: #fff;
        margin-left: 149px;
        margin-top: 7px;
        font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    }
    .cookie1{
      border:none;
        position: absolute;
        border: none;
        background: transparent;
        color: #fff;
        margin-left: 257px;
        margin-top: 7px;
        font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    }