body {
  background-image: url(./assets/images/newmobbg.svg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;

  align-content: space-between;
  font-family: Lato, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.modal-open {
  padding: 0 !important;
}

@media only screen and (min-width: 992px) {
  body {
    background: url(./assets/images/Background2.svg) top no-repeat,
      #effafc bottom;
    background-size: 100% 364px, auto auto;
  }
}

@supports (-webkit-touch-callout: none) {
  body{
  background-image: linear-gradient(#3395DC,#0F75BF,#00AEAE,#107AC2,#11FFFF,#6C63FF);
 }}
